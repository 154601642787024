<ul class="navbar-nav" *ngIf="isAuthenticated | async">
    <li class="nav-item">
        <a  class="nav-link text-dark" [routerLink]='["/authentication/profile"]' title="Manage">Hello {{ userName | async }}</a>
    </li>
    <li class="nav-item">
        <a  class="nav-link text-dark" [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="Logout">Logout</a>
    </li>
</ul>
<ul class="navbar-nav" *ngIf="!(isAuthenticated | async)">
  <li class="nav-item">
        <a class="nav-link text-dark" [routerLink]='["/authentication/register"]'>Register</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-dark" [routerLink]='["/authentication/login"]'>Login</a>
    </li>
</ul>
